export * from './interfaces.v1';
import { Interface } from '../module';
import * as V1 from './interfaces.v1';

// generic form of Scored Field
// IScoredField<boolean> equals to { value: boolean, score?: V1.TScore, changeHistory?: IFieldHistoryEntry<boolean>[]}
export interface IScoredField<T> {
  value: T;
  score?: V1.TScore;
  changeHistory?: IFieldHistoryEntry<T>[];
}

export interface IPatient
  extends Omit<V1.IPatient, 'effectiveDate' | 'history'> {
  effectiveDate?: string;
  history?: IHistoryAll[];
  isActive?: boolean;
}

export enum ParticipationEnum {
  IN_NETWORK = 'In Network',
  OUT_OF_NETWORK = 'Out Of Network',
  DPPO = 'DPPO',
  TOTAL = 'TOTAL',
  ADVANTAGE = 'Advantage',
  PPO = 'PPO+Premier',
  PREMIER = 'Premier Only',
}

export type IHistoryAll =
  | IHistory
  | IHistoryTeeth
  | IHistorySurfaces
  | IHistoryQuadrants
  | IHistoryArch
  | IHistoryTeethRange;

export interface IHistory extends Omit<V1.IHistory, 'date'> {
  date: string;
}

export interface IHistoryTeeth extends IHistory {
  tooth?: V1.TTooth[];
}
export interface IHistorySurfaces extends IHistory {
  surfaces?: { [key: V1.TTooth]: V1.TSurface }[];
}
export interface IHistoryTeethRange extends IHistory {
  teethRange?: V1.TTooth[];
}
export interface IHistoryQuadrants extends IHistory {
  quadrants?: V1.TQuadrant[];
}
export interface IHistoryArch extends IHistory {
  arch?: V1.TArch;
}

export type TAgeLimitation = IScoredField<number>;

export type TAgePlanScored = { max: TAgeLimitation };
export type TAgePlan = { max: number };

export interface ICorelatedWithCommonScored
  extends Omit<V1.ICorelatedWithCommonScored, 'apply' | 'data'> {
  apply: {
    value: boolean | null;
    score?: V1.TScore;
    changeHistory?: IFieldHistoryEntry<boolean | null>[];
  };
  data?: {
    value?: string[];
    score?: V1.TScore;
    changeHistory?: IFieldHistoryEntry<string | string[]>[];
    [key: string]: any;
  };
}

export interface ICorelatedWithWaitingPeriodScored
  extends Omit<V1.ICorelatedWithWaitingPeriodScored, 'apply' | 'data'> {
  apply: {
    value: boolean | null;
    score?: V1.TScore;
    changeHistory?: IFieldHistoryEntry<boolean | null>[];
  };
  data?: {
    value: V1.IWaitingPeriod;
    score?: V1.TScore;
    changeHistory?: IFieldHistoryEntry<V1.IWaitingPeriod>[];
  };
}

export type ICorelatedWithScored =
  | ICorelatedWithCommonScored
  | ICorelatedWithWaitingPeriodScored;

export interface IDetailsScored
  extends Omit<V1.IDetailsScored, 'benefitYear' | 'initiationDate'> {
  benefitYear?: IScoredField<string>;
  initiationDate?: string;
}

export interface IDetails extends Omit<V1.IDetails, 'initiationDate'> {
  initiationDate?: string;
}

export interface IAdaSimpleObjectScored
  extends Omit<
    V1.IAdaSimpleObjectScored,
    | 'exceptions'
    | 'coinsurance'
    | 'conflicts'
    | 'wholeCategory'
    | 'appliesToAnnualMax'
    | 'annualMax'
    | 'deductible'
    | 'waitingPeriod'
    | 'lifetimeMaximum'
    | 'limitation'
    | 'correlatedWith'
    | 'age'
  > {
  wholeCategory?: IScoredField<boolean>;
  appliesToAnnualMax?: IScoredField<boolean>;
  annualMax?: IScoredField<number>;
  benefitLevel?: IScoredField<number>;
  deductible?: IScoredField<number>;
  waitingPeriod?: IScoredField<V1.IWaitingPeriod>;
  lifetimeMaximum?: IScoredField<number>;
  limitation?: IScoredField<V1.TLimitation>;
  correlatedWith?: ICorelatedWithScored[];
  exceptions?: { [key: string]: IAdaSimpleObjectCustomExceptionScored };
  conflicts?: IAdaSimpleObjectCustomExceptionScored[];
  age?: {
    min?: TAgeLimitation;
    max?: TAgeLimitation;
  };
}

export interface IAdaSimpleObject
  extends Omit<
    V1.IAdaSimpleObject,
    'exceptions' | 'conflicts' | 'coinsurance' | 'age'
  > {
  benefitLevel?: number;
  exceptions?: { [key: string]: IAdaSimpleObjectException };
  conflicts?: IAdaSimpleObjectException[];
  age?: ISimpleAgeLimitation;
}

export interface ISimpleAgeLimitation {
  min?: number;
  max?: number;
}

export type TAdaCodesScored = { [key in V1.TAdaCode]?: IAdaSimpleObjectScored };

export type IAdaSimpleObjectCustomExceptionScored =
  Partial<IAdaSimpleObjectScored>;

export type IAdaSimpleObjectException = Partial<IAdaSimpleObject>;

export interface IBreakdownsScored
  extends Omit<
    V1.IBreakdownsScored,
    'missingToothClause' | 'annualMax' | 'deductible' | 'adaCodes'
  > {
  missingToothClause?: IScoredField<boolean>;
  annualMax?: IScoredField<number>;
  deductible: {
    individual?: IScoredField<number>;
    family?: IScoredField<number>;
  };
  age?: {
    child?: TAgePlanScored;
    student?: TAgePlanScored;
    dependent?: TAgePlanScored;
  };
  adaCodes: TAdaCodesScored;
}

export interface IAlertNotes extends Omit<V1.IAlertNotes, 'creationDate'> {
  creationDate: string;
}

export interface IBreakdowns extends Omit<V1.IBreakdowns, 'adaCodes'> {
  age?: {
    child?: TAgePlan;
    student?: TAgePlan;
    dependent?: TAgePlan;
  };
  adaCodes: { [key in V1.TAdaCode]?: IAdaSimpleObject };
}

export interface ISchemaPlan
  extends Omit<V1.ISchemaPlan, 'benefits' | 'lastSubmition' | 'details'> {
  benefits: IBreakdowns | IBreakdownsScored;
  details: IDetails | IDetailsScored;
  scoringEngineVersion?: string;
  lastSubmission?: string;
}

export interface ITicket
  extends Omit<V1.ITicket, 'draft' | 'resolvedDate' | 'alertNotes'> {
  draft?: ISchemaPlan | ISchemaPlan[];
  participationLevel?: Interface.TParticipationLevel;
  resolvedDate?: string;
  alertNotes?: IAlertNotes[];
  organizationId?: string;
  dentalOfficeId?: string;
  importedTimestamp?: string;
  disabledFields?: string[];
  verificationDate?: string;
  carrierName?: string;
  outsourced?: string;
  planErrors?: any;
  groupNumber?: string;
  createTime?: string;
}

export interface ISchemaAllPlan
  extends Omit<V1.ISchemaAllPlan, 'plan' | 'ticket' | 'patient'> {
  patient: IPatient;
  plan?: ISchemaPlan | ISchemaPlan[];
  ticket?: ITicket;
}

export interface IFieldHistoryEntry<valueType>
  extends Omit<V1.IFieldHistoryEntry<valueType>, 'timestamp' | 'value'> {
  newValue: valueType;
  oldValue?: valueType;
  newScore?: V1.TScore;
  oldScore?: V1.TScore;
  updatedAt: string;
  systemScoreDownOnPeriodChange?: boolean;
}

/**
 * @deprecated The type should not be used
 */
export type TCustomQuestions = {
  // because this object has a value: it could be mistaken for IScoredField<value> by isScoredField()
  // and in turn get scored, because it also have changeHistory, so ... should it also have a score: TScore?
  // it definitly needs to be updated if used
  [key: string]: {
    value: string;
    type: string;
    changeHistory?: IFieldHistoryEntry<string>[];
  };
};
