import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { matchPath, useHistory } from 'react-router';
import { routes, UNAUTHORIZED_URL, REGISTER_DENTAL_OFFICE_URL } from './RoutingTable';
import { RouteDefinition } from './types';
import { useAppRouter } from '../Contexts/AppRouterContext';
import { MainSpaceLayout } from '../Theme/MainSpaceLayout/MainSpaceLayout';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth0Verrific } from 'verrific-plus-schema';
import { FrameLoading } from '../Components/FrameLoading';
import { withAuth, withSplashRemove } from './AppRouter.hocs';
import { getOfficeSettings } from '../Screens/Private/DentalOfficeSettings/DentalOfficeSettings.api';

interface IAuthStates {
  logged: boolean;
  authorized: boolean;
}

const PATHS_ALLOWED_DURING_REGISTRATION = [
  '/logout',
  '/handleLogin',
  '/refreshToken',
  '/terms'
];

export const AppRouter = (): JSX.Element => {
  const { isAuthenticated, isLoading, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [isInitialized, setInitialized] = useState(false);

  const isAuth = async () => {
    try {
      await getAccessTokenSilently({ cacheMode: 'off', detailedResponse: true });
      const token = await getIdTokenClaims();
      if (token) {
        Auth0Verrific.setToken(token);
        localStorage.setItem('v+token', token.__raw);
      }
    } catch (e) {
      localStorage.clear();
      console.error('Error on getAccessTokenSilently', e);
      location.href = `/login?return=${location.pathname}`;
    }
    setInitialized(true);
  };

  const isLogged = (): IAuthStates => {
    return {
      logged: isAuthenticated,
      authorized: checkRole()
    };
  };

  const location = useLocation();
  const { setCurrentPageMeta, setDefaultPublic, setDefaultPrivate } = useAppRouter();
  const history = useHistory();
  const [publicRoutes, setPublicRoutes] = useState<RouteDefinition[]>();
  const [privateRoutes, setPrivateRoutes] = useState<RouteDefinition[]>(); 

  const init = async () => {
    if(!isInitialized){
      await isAuth();
    }
  };

  useEffect(() => {
    if (isInitialized) {
      if (Auth0Verrific.isDODuringRegistration() && !PATHS_ALLOWED_DURING_REGISTRATION.includes(location.pathname)) {
        history.push(REGISTER_DENTAL_OFFICE_URL);
        return;
      }
      if (isPrivateRoute()) {
        const authState = isLogged();
        if (!authState.logged) {
          history.push(`/login?return=${location.pathname}`);
        } else if (!authState.authorized) {
          history.push(UNAUTHORIZED_URL);
        }
      }
    }
  }, [isInitialized]);

  useEffect(() => {
    if(!isLoading){
      init();
    }
  }, [isLoading]);

  useEffect(() => {
    const routesArray = getFlatMapRouting(routes);
    const current = findCurrentPattern(routesArray, location.pathname);
    if (current) {
      setCurrentPageMeta(current);
      const pub = getDefaultPathObj('public');
      const priv = getDefaultPathObj('private');
      if (pub) {
        setDefaultPublic(pub);
      }
      if (priv) {
        setDefaultPrivate(priv);
      }
    } else if (isLogged().logged) {
      goToDefaultPrivate();
    } else {
      goToDefaultPublic();
    }
  }, [location.pathname]);

  const goToDefaultPrivate = () => {
    const routesArray = getFlatMapRouting(routes);
    const current = routesArray.filter((route) => route.private).find((route) => route.default);
    if (current) {
      setCurrentPageMeta(current);
      history.push(current.path);
    } else {
      goToDefaultPublic();
    }
  };

  const goToDefaultPublic = () => {
    const routesArray = getFlatMapRouting(routes);
    const current = routesArray.filter((route) => !route.private).find((route) => route.default);
    if (current) {
      setCurrentPageMeta(current);
      history.push(current.path);
    } else {
      history.push('/');
    }
  };

  const getFlatMapRouting = (routeArray: RouteDefinition[], prefix?: string): RouteDefinition[] => {
    let rts: RouteDefinition[] = [];
    routeArray.forEach((rt: RouteDefinition) => {
      const subPaths = rt.subpaths;
      rts.push({...rt, subpaths: undefined, path: `${prefix ? prefix : ''}${prefix?.endsWith('/') || rt.path.startsWith('/') ? '' : '/'}${rt.path}`});
      if (subPaths && subPaths.length) {
        const arr = getFlatMapRouting(subPaths, rt.path);
        rts = [...rts, ...arr];
      }
    });
    return rts;
  };

  const findCurrentPattern = (routesList: RouteDefinition[], path: string): RouteDefinition | undefined => {
    const matchCurrent = (rt: RouteDefinition): boolean => {
      return !!matchPath(path, {
        path: rt.path,
        exact: true,
        strict: false
      });
    };
    const res: RouteDefinition | undefined = routesList.find((el: RouteDefinition) => matchCurrent(el));
    return res;
  };

  const isPrivateRoute = () => {
    const path = location.pathname;
    const routesArray = getFlatMapRouting(routes);
    const current = findCurrentPattern(routesArray, path);
    return !!current?.private;
  };

  const getRoutes = (type?: 'private' | 'public' | 'all'): RouteDefinition[] => {
    const rts: RouteDefinition[] = getFlatMapRouting(routes);
    if (type === 'private') {
      return rts.filter(el => el.private);
    } else if (type === 'public') {
      return rts.filter(el => !el.private);
    }
    return rts;
  };

  const getDefaultPathObj = (type?: 'private' | 'public' | 'all'): RouteDefinition | undefined => {
    const rts: RouteDefinition[] = getRoutes(type);
    return rts.find(el => !el.disabled && el.default);
  };

  useEffect(() => {
    setPublicRoutes(getRoutes('public'));
    setPrivateRoutes(getRoutes('private'));
  }, []);

  
  const checkRole = (): boolean => {
    const routesArray = getFlatMapRouting(routes);
    const current = findCurrentPattern(routesArray, location.pathname);
    if (current?.private) {
      if (current.roles.includes('doregistration') && Auth0Verrific.isDODuringRegistration()) {
        return true;
      }
      return current.roles.some(role => Auth0Verrific.getRoles().includes(role));
    }
    return true;
  };

  const getLoading = () => {
    return <FrameLoading alternateLoading zIndex={2000} overAll isLoading={true}>
      <></>
    </FrameLoading>;
  };

  return (
    <MainSpaceLayout>
      <Switch>
        {isInitialized && <>
          {(isLogged().logged && isLogged().authorized) && (<>
            {publicRoutes?.map(route => (
              <Route key={uuidv4()} exact path={route.path}>
                <Suspense fallback={getLoading()}>
                  <route.Component />
                </Suspense>
              </Route>
            ))}
            {privateRoutes?.map(route => {
              const Component = withAuth(withSplashRemove(route.Component));
              return (
                <Route key={uuidv4()} exact path={route.path}>
                  <Suspense fallback={getLoading()}>
                    <Component />
                  </Suspense>
                </Route>
              );
            })}
          </>)} 
          {(!isLogged().logged || !isLogged().authorized) && (<>
            {publicRoutes?.map(route => (
              <Route key={uuidv4()} exact path={route.path}>
                <Suspense fallback={getLoading()}>
                  <route.Component />
                </Suspense>
              </Route>
            ))}
          </>)} 
        </>}
      </Switch>
    </MainSpaceLayout>
  );
};