import Validator from 'fastest-validator';
import * as IV3 from '../interfaces/interfaces.v3';

export const fastest = new Validator({
  defaults: {
    object: {
      strict: true,
    },
  },
  useNewCustomCheckerFunction: true,
  messages: {
    wrongAdaCode: 'Invalid adaCode',
    wrongSubcategory: 'Invalid subcategory',
    wrongCategory: 'Invalid category',
    correlationHow: 'Wrong correlation how value',
    timePeriod: 'Wrong time period',
    wrongCaveat: 'Wrong caveat type',
    waitingPeriodCorrelation: 'Wrong waiting period correlation',
    sponsorType: 'Wrong sponor type',
    tooth: 'Wrong tooth ID',
    quadrant: 'Wrong quadrant',
    arch: 'Wrong arch',
    wrongKey: 'Wrong key or keys in adaObject',
    toothKey: 'Wrong tooth as key in surface history',
    surfaceValue: 'Wrong surface as value in surface history',
    relationshipToSubscriber: 'Wrong relationship to subscriber',
    ticketStatus: 'Wrong ticket status',
    dateError: 'Wrong date',
    mappingInfo: 'Wrong mapping info entry',
    coverageStatus: 'Wrong coverage status',
    planType: 'Wrong plan type',
    payerSpecificPlantype: 'Wrong payer specific plan type',
  },
});

export class ValidatorV3 {
  private static instance: ValidatorV3;
  public static getInstance() {
    if (!ValidatorV3.instance) {
      ValidatorV3.instance = new ValidatorV3();
    }

    return ValidatorV3.instance;
  }

  checkIfIncludedInArray(
    array: readonly (string | number)[],
    errorMessage: string,
  ) {
    return (value: any, errors: any[]) => {
      if (value !== undefined && !array.includes(value)) {
        errors.push({ type: errorMessage });
      }
      return value;
    };
  }

  adaCode() {
    return {
      type: 'string',
      custom: this.checkIfIncludedInArray(IV3.AdaCodeArray, 'wrongAdaCode'),
    };
  }

  score = {
    type: 'number',
    min: 1,
    max: 4,
    optional: true,
  };

  fieldHistory() {
    return {
      type: 'array',
      items: {
        type: 'object',
        props: {
          userHash: {
            type: 'string',
          },
          updatedAt: {
            type: 'string',
          },
          value: {
            type: 'any',
            optional: true,
          },
          oldValue: {
            type: 'any',
            optional: true,
          },
          newValue: {
            type: 'any',
            optional: true,
          },
          newScore: {
            type: 'any',
            optional: true,
          },
          oldScore: {
            type: 'any',
            optional: true,
          },
        },
      },
      optional: true,
    };
  }

  VScoredField(fieldType: 'string' | 'number' | 'boolean') {
    return {
      value: {
        type: fieldType,
      },
      score: this.score,
      changeHistory: this.fieldHistory(),
    };
  }

  scoreFields(object: any, fields: string[]) {
    for (const field of fields) {
      if (object[field] === undefined) {
        continue;
      }
      let fieldOptional = false;
      if (object[field].optional) {
        delete object[field].optional;
        fieldOptional = true;
      }
      object[field] = {
        type: 'object',
        props: {
          value: object[field],
          score: this.score,
          changeHistory: this.fieldHistory(),
        },
        optional: fieldOptional,
      };
    }
  }

  typeOrArrayOfType = (type: 'string' | 'number') => [
    { type },
    { type: 'array', items: type },
  ];

  VCaveat(scored: boolean) {
    if (scored) {
      return {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            value: {
              type: 'string',
            },
            score: this.score,
            changeHistory: this.fieldHistory(),
          },
        },
      };
    }

    return { type: 'array', items: 'string' };
  }

  VAdaObject(scored: boolean) {
    const adaObjectSchema: { [key: string]: any } = {
      category: {
        type: 'string',
        custom: this.checkIfIncludedInArray(IV3.CategoryArray, 'wrongCategory'),
      },
      subcategory: {
        type: 'string',
        custom: this.checkIfIncludedInArray(
          IV3.SubcategoryArray,
          'wrongSubcategory',
        ),
        optional: true,
      },
      code: { ...this.adaCode(), optional: true },
      appliesToAnnualMax: scored
        ? {
            type: 'object',
            props: this.VScoredField('boolean'),
            optional: true,
          }
        : { type: 'boolean', optional: true },
      annualMax: scored
        ? { type: 'object', props: this.VScoredField('number'), optional: true }
        : { type: 'number', optional: true },
      benefitLevel: scored
        ? { type: 'object', props: this.VScoredField('number'), optional: true }
        : { type: 'number', optional: true },
      deductible: scored
        ? { type: 'object', props: this.VScoredField('number'), optional: true }
        : { type: 'number', optional: true },
      waitingPeriod: {
        type: 'object',
        props: this.VWaitingPeriod(scored),
        optional: true,
      },
      lifetimeMaximum: scored
        ? { type: 'object', props: this.VScoredField('number'), optional: true }
        : { type: 'number', optional: true },
      limitation: { type: 'object', props: this.VLimitation(scored) },
      correlatedWith: this.VCorrelatedWith(scored),
      caveat: this.VCaveat(scored),
      exceptions: {
        type: 'object',
        props: {
          child: {
            type: 'object',
            props: this.VPartialAdaObject(scored),
            optional: true,
          },
          dependent: {
            type: 'object',
            props: this.VPartialAdaObject(scored),
            optional: true,
          },
          student: {
            type: 'object',
            props: this.VPartialAdaObject(scored),
            optional: true,
          },
        },
        optional: true,
      },
      age: { type: 'object', props: this.VAge(scored), optional: true },
    };

    if (!scored) {
      adaObjectSchema.mappingInfo = {
        type: 'array',
        items: {
          type: 'object',
          props: {
            message: {
              type: 'string',
              custom: this.checkIfIncludedInArray(
                Object.values(IV3.MappingInfoArray),
                'mappingInfo',
              ),
            },
            field: {
              type: 'string',
            },
          },
        },
        optional: true,
      };
    }

    return adaObjectSchema;
  }

  VPartialAdaObject(scored: boolean) {
    return {
      category: {
        type: 'string',
        custom: this.checkIfIncludedInArray(IV3.CategoryArray, 'wrongCategory'),
        optional: true,
      },
      subcategory: {
        type: 'string',
        custom: this.checkIfIncludedInArray(
          IV3.SubcategoryArray,
          'wrongSubcategory',
        ),
        optional: true,
      },
      code: { ...this.adaCode(), optional: true },
      appliesToAnnualMax: scored
        ? {
            type: 'object',
            props: this.VScoredField('boolean'),
            optional: true,
          }
        : { type: 'boolean', optional: true },
      annualMax: scored
        ? { type: 'object', props: this.VScoredField('number'), optional: true }
        : { type: 'number', optional: true },
      benefitLevel: scored
        ? { type: 'object', props: this.VScoredField('number'), optional: true }
        : { type: 'number', optional: true },
      deductible: scored
        ? { type: 'object', props: this.VScoredField('number'), optional: true }
        : { type: 'number', optional: true },
      waitingPeriod: {
        type: 'object',
        props: this.VWaitingPeriod(scored),
        optional: true,
      },
      lifetimeMaximum: scored
        ? { type: 'object', props: this.VScoredField('number'), optional: true }
        : { type: 'number', optional: true },
      limitation: { type: 'object', props: this.VLimitation(scored) },
      correlatedWith: this.VCorrelatedWith(scored),
      caveat: this.VCaveat(scored),
      age: { type: 'object', props: this.VAge(scored), optional: true },
    };
  }

  VCorrelatedWith(scored: boolean) {
    const schema: any = {
      type: 'array',
      items: {
        type: 'object',
        props: {
          code: this.adaCode(),
          how: {
            type: 'string',
            custom: this.checkIfIncludedInArray(
              Object.values(IV3.CorrelationEnum),
              'correlationHow',
            ),
          },
          apply: {
            type: 'boolean',
          },
          data: {
            type: 'any',
            optional: true,
            $$strict: false,
          },
        },
      },
    };
    if (scored) {
      this.scoreFields(schema.items.props, ['apply', 'data']);
    }

    return schema;
  }

  VWaitingPeriod(scored: boolean) {
    let schema: any = {
      codes: {
        type: 'array',
        items: this.adaCode(),
        optional: true,
      },
      months: {
        type: 'number',
        optional: true,
        nullable: true,
      },
      days: {
        type: 'number',
        optional: true,
        nullable: true,
      },
      years: {
        type: 'number',
        optional: true,
        nullable: true,
      },
      weeks: {
        type: 'number',
        optional: true,
        nullable: true,
      },
    };
    if (scored) {
      const copiedSchema = { ...schema };
      schema = {
        value: { type: 'object', props: copiedSchema },
        score: this.score,
        changeHistory: this.fieldHistory(),
      };
    }
    return schema;
  }

  VLimitation(scored: boolean) {
    let schema: any = {
      quantity: {
        type: 'number',
      },
      frequency: {
        type: 'number',
      },
      timePeriod: {
        type: 'string',
        custom: this.checkIfIncludedInArray(IV3.TTimePeriodArray, 'timePeriod'),
      },
    };
    if (scored) {
      const copiedSchema = { ...schema };
      schema = {
        value: { type: 'object', props: copiedSchema },
        score: this.score,
        changeHistory: this.fieldHistory(),
      };
    }
    return schema;
  }

  VAge(scored: boolean) {
    const schema: any = {
      min: {
        type: 'number',
        min: 0,
        optional: true,
      },
      max: {
        type: 'number',
        max: 1000,
        optional: true,
      },
    };

    if (scored) {
      this.scoreFields(schema, ['min', 'max']);
    }
    return schema;
  }

  VBreakdownAge(scored: boolean) {
    return {
      child: { type: 'object', props: this.VAge(scored), optional: true },
      student: { type: 'object', props: this.VAge(scored), optional: true },
      dependent: { type: 'object', props: this.VAge(scored), optional: true },
    };
  }

  VAttachments = {
    $$root: true,
    type: 'array',
    items: 'url',
  };

  VSponsor = {
    sponsorName: {
      type: 'string',
      optional: true,
    },
    sponsorType: {
      type: 'string',
      custom: this.checkIfIncludedInArray(IV3.SponsorTypeArray, 'sponsorType'),
      optional: true,
    },
    groupNumber: {
      type: 'string',
    },
    groupName: {
      type: 'string',
      optional: true,
    },
  };

  VPayer = {
    id: {
      type: 'string',
      optional: true,
    },
    name: {
      type: 'string',
    },
    street1: {
      type: 'string',
      optional: true,
    },
    street2: {
      type: 'string',
      optional: true,
    },
    city: {
      type: 'string',
      optional: true,
    },
    state: {
      type: 'string',
      optional: true,
    },
    zipCode: {
      type: 'string',
      optional: true,
    },
    phoneNumber: {
      type: 'string',
      optional: true,
    },
    typeOfVerification: {
      type: 'string',
      optional: true,
    },
    createdAsNotSure: {
      type: 'boolean',
      optional: true,
    },
    masterDBCarrierID: {
      type: 'string',
      optional: true,
    }
  };

  VPlanMetadata = {
    payerSpecificPlanType: { ...this.payerSpecificPlanType(), optional: true },
  };

  payerSpecificPlanType() {
    return {
      type: 'object',
      props: {
        matchedValue: {
          type: 'string',
          custom: this.checkIfIncludedInArray(
            Object.values(IV3.PayerSpecificPlanType),
            'payerSpecificPlantype',
          ),
        },
        originalValue: {
          type: 'string',
        },
      },
    };
  }

  VHistoryDate = {
    type: 'string',
    custom(value: any, errors: any[]) {
      if (isNaN(Date.parse(value))) {
        errors.push({ type: 'dateError' });
      }
      return value;
    },
  };

  VHistoryRecord = {
    code: this.adaCode(),
    date: this.VHistoryDate,
    notes: {
      type: 'string',
      optional: true,
    },
    tooth: {
      type: 'array',
      items: {
        type: 'string',
        custom: this.checkIfIncludedInArray(IV3.ToothArray, 'tooth'),
      },
      optional: true,
    },
    surfaces: {
      type: 'array',
      items: {
        type: 'object',
        custom(value: any, errors: any[]) {
          const keys = Object.keys(value);
          const values = Object.values(value) as string[];
          if (
            !keys.every((key) =>
              (IV3.ToothArray as ReadonlyArray<string>).includes(key),
            )
          ) {
            errors.push({ type: 'toothKey' });
          } else if (
            !values.every((value) =>
              [
                ...IV3.SurfaceAnteriorArray,
                ...IV3.SurfacePostteriorArray,
              ].includes(value),
            )
          ) {
            const test = [
              ...IV3.SurfaceAnteriorArray,
              ...IV3.SurfacePostteriorArray,
            ];
            errors.push({ type: 'surfaceValue' });
          }
          return value;
        },
      },
      optional: true,
    },
    quadrants: {
      type: 'array',
      items: {
        type: 'string',
        custom: this.checkIfIncludedInArray(IV3.QuadrantArray, 'quadrant'),
      },
      optional: true,
    },
    arch: {
      type: 'string',
      custom: this.checkIfIncludedInArray(IV3.ArchArray, 'arch'),
      optional: true,
    },
    teethRange: {
      type: 'array',
      items: {
        type: 'string',
        custom: this.checkIfIncludedInArray(IV3.ToothArray, 'tooth'),
      },
      optional: true,
    },
  };

  VUnmappedHistoryRecord = {
    originalBenefitKey: {
      type: 'string',
      optional: true,
    },
    code: {
      type: 'string',
      optional: true,
    },
    date: this.VHistoryDate,
    isWrongDate: {
      type: 'boolean',
      optional: true,
    },
  };

  VPatient = {
    deductibleRemaining: {
      type: 'number',
      optional: true,
    },
    insuranceRemaining: {
      type: 'number',
      optional: true,
    },
    effectiveDate: {
      type: 'string',
      optional: true,
    },
    note: {
      type: 'string',
      optional: true,
    },
    feeSchedule: {
      type: 'string',
      optional: true,
    },
    history: {
      type: 'array',
      items: { type: 'object', props: this.VHistoryRecord },
      optional: true,
    },
    unmappedHistory: {
      type: 'array',
      items: { type: 'object', props: this.VUnmappedHistoryRecord },
      optional: true,
    },
    coverageStatus: {
      type: 'string',
      custom: this.checkIfIncludedInArray(
        IV3.CoverageStatusArray,
        'coverageStatus',
      ),
      optional: true,
    },
    planEndDate: { type: 'string', nullable: true, optional: true },
  };

  VEligibility = {
    deductibleRemaining: {
      type: 'number',
      optional: true,
    },
    insuranceRemaining: {
      type: 'number',
      optional: true,
    },
    effectiveDate: {
      type: 'string',
      optional: true,
    },
    feeSchedule: {
      type: 'string',
      optional: true,
    },
    coverageStatus: {
      type: 'string',
      custom: this.checkIfIncludedInArray(
        IV3.CoverageStatusArray,
        'coverageStatus',
      ),
      optional: true,
    },
    planEndDate: { type: 'string', nullable: true, optional: true },
    isActive: {
      type: 'boolean',
      optional: true,
    },
    participationLevel: {
      type: 'string',
    },
  };

  VReverification = {
    note: {
      type: 'string',
      optional: true,
    },
    history: {
      type: 'array',
      optional: true,
    },
    eligibility: {
      type: 'array',
      items: { type: 'object', props: this.VEligibility },
    },
    unmappedHistory: {
      type: 'array',
      optional: true,
    },
    feeSchedule: {
      type: 'string',
      optional: true,
    },
  };

  VBreakdown(scored: boolean) {
    const breakdownSchema: { [key: string]: any } = {
      missingToothClause: scored
        ? {
            type: 'object',
            props: this.VScoredField('boolean'),
            optional: true,
          }
        : { type: 'boolean', optional: true },
      annualMax: scored
        ? {
            type: 'object',
            props: this.VScoredField('number'),
            optional: true,
          }
        : { type: 'number', optional: true },
      deductible: {
        type: 'object',
        props: {
          individual: scored
            ? {
                type: 'object',
                props: this.VScoredField('number'),
                optional: true,
              }
            : { type: 'number', optional: true },
          family: scored
            ? {
                type: 'object',
                props: this.VScoredField('number'),
                optional: true,
              }
            : { type: 'number', optional: true },
        },
      },
      age: {
        type: 'object',
        props: this.VBreakdownAge(scored),
        optional: true,
      },
      adaCodes: {
        type: 'object',
        custom(value: any, errors: any[]) {
          if (!value) {
            errors.push({ field: 'adaCodes', message: 'is required' });
          }
          const keys = Object.keys(value);
          let currentKey;
          if (
            !keys.every((key) => {
              currentKey = key;
              return (
                (IV3.CategoryArray as ReadonlyArray<string>).includes(key) ||
                (IV3.SubcategoryArray as ReadonlyArray<string>).includes(key) ||
                (IV3.AdaCodeArray as ReadonlyArray<string>).includes(key)
              );
            })
          ) {
            errors.push({ type: `wrongKey ${currentKey}` });
          }
          return value;
        },
      },
    };

    if (!scored) {
      breakdownSchema.mappingInfo = {
        type: 'array',
        items: {
          type: 'object',
          props: {
            message: {
              type: 'string',
              custom: this.checkIfIncludedInArray(
                Object.values(IV3.MappingInfoArray),
                'mappingInfo',
              ),
            },
            field: {
              type: 'string',
            },
          },
        },
        optional: true,
      };
    }

    return breakdownSchema;
  }

  VDetails(scored: boolean) {
    if (scored) {
      return {
        benefitYear: {
          type: 'object',
          props: this.VScoredField('string'),
          optional: true,
        },
        planType: {
          type: 'string',
          custom: this.checkIfIncludedInArray(IV3.PlanTypeArray, 'planType'),
          optional: true,
        },
        participation: { type: 'string' },
        initiationDate: { type: 'string', nullable: true, optional: true },
        mappingInfo: {
          type: 'array',
          items: {
            type: 'object',
            props: {
              message: {
                type: 'string',
                custom: this.checkIfIncludedInArray(
                  Object.values(IV3.MappingInfoArray),
                  'mappingInfo',
                ),
              },
              field: {
                type: 'string',
              },
            },
          },
          optional: true,
        },
      };
    } else {
      return {
        benefitYear: { type: 'string', optional: true },
        planType: {
          type: 'string',
          custom: this.checkIfIncludedInArray(IV3.PlanTypeArray, 'planType'),
          optional: true,
        },
        participation: { type: 'string' },
        initiationDate: { type: 'string', nullable: true, optional: true },
        mappingInfo: {
          type: 'array',
          items: {
            type: 'object',
            props: {
              message: {
                type: 'string',
                custom: this.checkIfIncludedInArray(
                  Object.values(IV3.MappingInfoArray),
                  'mappingInfo',
                ),
              },
              field: {
                type: 'string',
              },
            },
          },
          optional: true,
        },
      };
    }
  }

  VPlanNotes = {
    creationDate: {
      type: 'string',
    },
    alertContent: {
      type: 'string',
    },
    user: {
      type: 'string',
    },
  };

  VPlan(scored: boolean) {
    const schema: any = {
      benefits: { type: 'object', props: this.VBreakdown(scored) },
      planNotes: {
        type: 'array',
        items: { type: 'object', props: this.VPlanNotes },
        optional: true,
      },
      details: { type: 'object', props: this.VDetails(scored) },
      scoringEngineVersion: {
        type: 'string',
        optional: true,
      },
      lastSubmission: {
        type: 'string',
        optional: true,
      },
      payer: { type: 'object', props: this.VPayer },
      sponsor: { type: 'object', props: this.VSponsor },
      caveat: { type: 'array', items: 'any', optional: true },
      planMetadata: {
        type: 'object',
        props: this.VPlanMetadata,
        optional: true,
      },
      userHashes: {
        type: 'array',
        items: 'string',
        optional: true,
      },
      planHash: {
        type: 'string',
        optional: true,
      },
      lastSubmition: {
        type: 'string',
        optional: true,
      },
      schemaVersion: {
        type: 'string',
        optional: true,
      },
    };

    if (scored) {
      schema.score = {
        type: 'number',
        min: 0,
        max: 10,
        optional: true,
      };
    }

    return schema;
  }

  VClientInfo = {
    name: {
      type: 'string',
      optional: true,
    },
    primaryDoctorName: {
      type: 'string',
      optional: true,
    },
    taxId: {
      type: 'string',
      optional: true,
    },
    phone: {
      type: 'string',
      optional: true,
    },
    npi: {
      type: 'string',
      optional: true,
    },
    address: {
      type: 'string',
      optional: true,
    },
    maleAgent: {
      type: 'string',
      optional: true,
    },
    femaleAgent: {
      type: 'string',
      optional: true,
    },
  };

  VPatientInfo = {
    patientFirstName: {
      type: 'string',
    },
    patientLastName: {
      type: 'string',
    },
    patientDOB: {
      type: 'string',
    },
    patientMemberId: {
      type: 'string',
    },
    patientAddress: {
      type: 'string',
      optional: true,
    },
    patientRelationToSubscriber: {
      type: 'string',
      custom: this.checkIfIncludedInArray(
        Object.values(IV3.RelationToSubscriberEnum),
        'relationshipToSubscriber',
      ),
    },
    subscriberFirstName: {
      type: 'string',
      optional: true,
    },
    subscriberLastName: {
      type: 'string',
      optional: true,
    },
    subscriberDOB: {
      type: 'string',
      optional: true,
    },
    subscriberMemberId: {
      type: 'string',
      optional: true,
    },
  };

  VTicket(scored: boolean) {
    return {
      integrationPatientInfo: {
        type: 'object',
        props: this.VPatientInfo,
        optional: true,
      },
      integrationClientInfo: {
        type: 'object',
        props: this.VClientInfo,
        optional: true,
      },
      resolvedDate: {
        type: 'string',
        optional: true,
      },
      draft: {
        type: 'array',
        items: { type: 'object', props: this.VPlan(scored) },
        optional: true,
      },
      submitPlanId: {
        type: 'string',
        optional: true,
      },
      status: {
        type: 'string',
        custom: this.checkIfIncludedInArray(
          Object.values(IV3.TicketStatusEnum),
          'ticketStatus',
        ),
        optional: true,
      },
      verrificPlusInteralId: {
        type: 'string',
      },
      clientSpecificPlanId: {
        type: 'string',
        optional: true,
      },
      payerAgentName: {
        type: 'string',
        optional: true,
      },
      callReference: {
        type: 'string',
        optional: true,
      },
      documents: { ...this.VAttachments, optional: true },
      externalUrl: {
        type: 'string',
        optional: true,
      },
      integration: {
        type: 'string',
        optional: true,
      },
      externalId: {
        type: 'string',
        optional: true,
      },
      ticketHash: {
        type: 'string',
        optional: true,
      },
      schemaVersion: {
        type: 'string',
        optional: true,
      },
      isDirty: {
        type: 'boolean',
        optional: true,
      },
      requesterId: {
        type: 'string',
        optional: true,
      },
      imported: {
        type: 'boolean',
        optional: true,
      },
      ehr: {
        type: 'string',
        optional: true,
      },
      ehrVersion: {
        type: 'string',
        optional: true,
      },
      pluginName: {
        type: 'string',
        optional: true,
      },
      pluginVersion: {
        type: 'string',
        optional: true,
      },
      pluginEnvironment: {
        type: 'string',
        optional: true,
      },
    };
  }

  VTicketWrapper(scored: boolean) {
    return {
      patient: { type: 'object', props: this.VPatient },
      plan: {
        type: 'object',
        props: this.VPlan(scored),
        nullable: true,
        optional: true,
      },
      ticket: { type: 'object', props: this.VTicket(scored), optional: true },
      schemaVersion: {
        type: 'string',
        optional: true,
      },
    };
  }
}
